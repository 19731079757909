@import './card/card.scss';
@import './fallbacks/error.scss';
@import './fallbacks/loading.scss';
@import './forms/forms.scss';
@import './navbar/navbar.scss';
@import './other/other.scss';
@import './template/template.scss';
@import './translation/translation.scss';
@import './parameters//parameters.scss';
@import './wbs/wbs.scss';
@import './invoice/invoice.scss';
@import './planner/planner.scss';

.page {
    width: 100%;
}

/*** BANNER ***/

.banner {
    padding: 15px;
    text-align: center;
    vertical-align: middle;
    font-weight: 600;
}

.banner-close {
    position: absolute;
    right: 15px;
    cursor: pointer;
}

/*** USER DROPDOWN ***/

.navbar-user-dropdown {
    position: relative;
    height: 100%;
    cursor: pointer;
}

.navbar-user-dropdown-button {
    padding: 0 10px;
    line-height: 64px;
    span {
        font-weight: 600;
    }
}

.navbar-user-dropdown-options {
    position: absolute !important;
    top: 64px;
    right: 0;
    padding: 0 !important;
    white-space: nowrap;
}

/*** INCARNATION DIALOG ***/

.incarnation-dialog div[role=dialog] {
    min-width: 15vw;
    div:nth-child(2) {
        padding: 0;
        ul {
            padding: 0;
        }
    }
}

.incarnation-dialog-user-button {
    cursor: pointer;
    padding: 12px 24px !important;
}

/*** DATETIME PICKER ***/

.datetime-picker-popover-buttons {
    display: flex;
    padding: 0 5px 10px 5px;
    justify-content: space-between;
}

.app-kit-overflow-y{
    overflow-y: scroll;
    &::-webkit-scrollbar {
        width: 8px;
        height: 4px;
    }
    &::-webkit-scrollbar:horizontal {
        width: 4px;
        height: 4px;
    }
    &::-webkit-scrollbar-button{
        background-color: #ccc;
        height: 0px;
        width:0px;
    }
    &::-webkit-scrollbar-track-piece{
        background: transparent;
    }
    &::-webkit-scrollbar-thumb{
        background: lightgrey;
    }
}

.cursor-pointer{
    cursor: pointer !important;
}

.hover-black{
    &:hover{
        color:black
    }
}

.hover-pointer{
    &:hover{
        cursor: pointer;
    }
}