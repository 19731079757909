/*** SETTINGS ***/
.template-box{
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: scroll;
    border-radius: 5px;
    overflow-x: hidden;
    width:100%;
    padding: 25px;
    box-sizing: border-box;
    h2{
        margin-top: 30px;
        margin-bottom: 20px;
    }
    h1{
        padding-left: 30px;
    }
    button{
        margin-top: 50px;
    }
}

.template-box::-webkit-scrollbar {
    display: none;
}

.template-input{
    width:100%;
    background-color:"white";
    border-radius: 3px;
}

/*** EXPLORER ***/

.template-explorer {
    flex: 25;
    min-width: 200px;
    padding: 0 !important;
}

.template-explorer-input {
    padding-left: 5px;
}

.template-explorer-list {
    height: calc(100% - 56px);
    overflow-y: auto;
}

.template-explorer-button {
    display: flex;
    padding: 15px;
    padding-left: 20px;
    cursor: pointer;
    div {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}