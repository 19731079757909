@import './app/app.scss';

body {
    margin: 0;
    font-family: "Trebuchet MS", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: "Trebuchet MS", source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

/*** Handy classes ***/

.no-select {
    user-select: none;
}

.d-none {
    display: none !important;
}

.hidden {
    visibility: hidden;
}

.zero {
    height: 0;
    width: 0;
}

.mt-10 {
    margin-top: 10px !important;
}

.cursor-pointer {
    cursor: pointer;
}

.ellipse {
    overflow: hidden;
    text-overflow: ellipsis;
    // For list item texts
    & > span {
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.mr-20 {
    margin-right: 20px !important;
}