.cards-row {
    display: flex;
    max-height: 100%;
}

.card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 5px;
    margin-left: 25px;
    padding: 25px;
    padding-left: 25px;
    box-sizing: border-box;
    flex: 1;
    position: relative;
    &:first-child {
        margin-left: 0;
    }
}

// .card-header {

// }

.card-body {
    margin-top: 15px;
}

.card-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    button {
        margin-left: 5px;
        &:first-child {
            margin-left: 0;
        }
    }
}

.card-title {
    padding-left: 10px;
    margin-bottom: 10px !important;
}