.wbs-planning-category{
    font-weight: bold;
}
.wbs-planning-button{
    padding: 3px 3px;
    border-radius: 50%;
    color: white;
    cursor: pointer;
    &:hover{
        background-color: gray !important;
    }
}
.wbs-planning-input{
    margin-left: auto;
    margin-right: auto;
    border:none;
    background-color: white;
    width:30px;
    text-align: center;
    border: 1px solid #dedede;
    &:focus{
        outline:none;
        background-color: rgb(245, 245, 245);
    }
    &::-webkit-outer-spin-button{
        -webkit-appearance: none;
        margin: 0;
    }
    &::-webkit-inner-spin-button{
        -webkit-appearance: none;
        margin: 0;
    }
    &[type=number]{
        -moz-appearance: textfield;
    }
    &:hover{
        background-color: rgb(245, 245, 245);
        cursor: pointer;
    }
    &:disabled{
        color:rgb(182, 182, 182)
    }
}

.wbs-planning-chevron{
    margin-top: auto;
    margin-bottom: auto;
}

.wbs-planning-button-text{
    cursor: pointer !important;
    margin-right: 20px;
    &:hover{
        color: lightgray;
    }
}

.planning-new-value{
    position:absolute;
    color:rgb(206, 134, 0);
    left:50px;
}

.planning-verif-button{
    margin-top: auto;
}