.structure-task{
    max-height: 40px;
    padding-bottom: 10px;
}
.structure-task-content{
    display: flex;
    max-height: 100px;
}
.structure-task-code{
    margin-left: 5px;
    margin-right: 5px;
}
.structure-task-icon{
    margin-right: 10px;
    margin-top: 5px;
}
.structure-wbs{
    display: flex;
    margin-bottom: 10px;
}
.structure-wbs-name{
    color: white;
    height: 55px;
    width: 50%;
    font-size: 24px;
    border-radius: 5px;
    padding-left: 10px;
}
.structure-wbs-id{
    margin: 10px;
}
.input-color-form-wbs{
    input[type=color]{
        height: 30px;
        border-bottom: none;
        max-width: 100px;
    }
}