/*** EXPLORER ***/

.translation-explorer {
    flex: 25;
    min-width: 200px;
    padding: 0 !important;
}

.translation-explorer-list {
    height: calc(100% - 56px);
    overflow-y: overlay;
    max-height: -webkit-fill-available;
}

.translation-explorer-button {
    display: flex;
    padding: 15px;
    padding-left: 0;
    cursor: pointer;
    box-sizing: border-box;
    div {
        &:first-child {
            display: inline-block;
            width: 50px;
            text-align: center;
            flex-shrink: 0;
        }
        &:last-child {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
}

.translation-explorer-input {
    padding-left: 5px;
}

/*** TRANSLATOR ***/

.translation-translator {
    position: relative;
    flex: 75;
}

.translation-breadcrumb {
    margin-top: 10px;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 600;
}

.translation-reference {
    margin: 5px 0 50px 0;
    padding: 15px;
    border-radius: 5px;
}

.translation-translation-buttons {
    display: flex;
    justify-content: flex-end;
    button:last-child {
        margin-left: 5px;
    }
}

.translation-language-picker {
    position: absolute;
    right: 25px;
    top: 25px;
}