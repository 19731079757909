.progress-bar-task {
    width: 150px;
    float: left;
    margin-top: 5px;
    margin-left: 10px;
}

.progress-bar-task-content {
    margin-top: 0px;
    width: 100%;
    background-color: #e0e0e0;
    padding: 2.5px;
    border-radius: 5px;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, .2);
}

.progress-bar-task-fill {
    display: block;
    height: 15px;
    background-color: #E715C5;
    border-radius: 3px;
    transition: width 500ms ease-in-out;
}