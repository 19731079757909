@import './Task.scss';
@import './Structure.scss';
@import './ProgressBarWbs.scss';
@import './ProgressBarTask.scss';
@import './planning.scss';

.WbsName{
    line-height: 75px;
    padding-left: 15px;
}

#dropDown{
    border: 0px;
    outline: none;
    background-color: rgba(255, 255, 255, 0);
    font-size: 22px;
    height: 22px;
    width: 22px;
    
}

.Modal {
    z-index: 1000;
    position: fixed;
    top:0; right:0; bottom:0; left:0;
    background-color: white;
    box-shadow: 5px 5px 15px 5px rgba(0,0,0,0.71);
    padding: 16px;
    width: 500px;
    box-sizing: border-box;
    margin: auto;
    margin-top: 0px;
}

.wbs-add-task-button{
    &:hover{
        cursor: pointer;
        color: grey !important;
    }
}

.wbs-body-structure{
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    margin-top: 20px;
    border: 1px solid rgba(224, 224, 224, 1);
    overflow-y: scroll;
    background-color: white;
}

