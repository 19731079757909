/*** ALL CARDS ***/

.other-card {
    flex: 1;
}

// .admin-other-card-header {

// }

.other-card-body {
    padding-top: 15px;
}

.other-card-buttons {
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
    button {
        margin-left: 5px;
        &:first-child {
            margin-left: 0;
        }
    }
}

/*** BANNER CARD ***/

.other-banner-dates {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
}

.other-banner-option {
    min-width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.other-banner-option-remove {
    position: relative;
    left: 10px
}

.permissions-group-list{
    overflow-y: scroll;
    max-height: 100%;
}

.no-select-text{
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.no-select-text::selection{
    background: transparent;
}

.no-select-text::-moz-selection{
    background: transparent;
}