.app-navbar {
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.app-navbar-right {
    display: flex;
    height: 64px;
    align-items: center;
    & > div:first-child {
        margin-right: 25px;
    }
}

/*** DROPDOWN ***/

.navbar-dropdown {
    position: relative;
    line-height: 64px;
}

.navbar-dropdown-items {
    position: absolute !important;
    background-color: white;
    top: 64px;
    right: 0;
    padding: 0 !important;
    white-space: nowrap;
}

/*** USER DROPDOWN ***/

.navbar-user-dropdown-button {
    padding: 0 10px;
    line-height: 64px;
    span {
        font-weight: 600;
    }
}

/*** NOTIFICATIONS DROPDOWN ***/

.navbar-notifications-mark-all-read {
    min-width: 45px !important;
    margin-left: 10px !important;
}

.navbar-notifications-dropdown-buttons {
    max-width: 400px;
}

.navbar-notifications-dropdown-button-icon {
    min-width: 42px !important;
}

/*** NOTIFICATION DIALOG ***/

.notification-dialog div[role=dialog] {
    min-width: 15vw;
}

.notification-dialog-message {
    margin-top: 5px !important;
}

/*** INCARNATION DIALOG ***/

.incarnation-dialog div[role=dialog] {
    min-width: 15vw;
    div:nth-child(2) {
        padding: 0;
        ul {
            padding: 0;
        }
    }
}

.incarnation-dialog-user-button {
    padding: 12px 24px !important;
}

.tab-item{
    button[aria-selected=true]{
        background-color: white;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }
}

.link-path{
    color:white;
    text-decoration: none;
    &:hover{
        color:lightgray;
        cursor:pointer;
    }
}