/*** EXPLORER ***/

.parameters-explorer {
    flex: 25;
    min-width: 200px;
    padding: 0 !important;
}

.parameters-explorer-input {
    padding-left: 5px;
    .MuiFilledInput-input{
        padding-top: 1.2em;
        padding-bottom: 1.1em;
    }
}

.parameters-explorer-list {
    height: calc(100% - 56px);
    overflow-y: auto;
}

.parameters-explorer-button {
    display: flex;
    padding: 15px;
    padding-left: 20px;
    cursor: pointer;
    div {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}

/*** SETTINGS ***/

.parameters-settings {
    position: relative;
    flex: 75;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.paramaters-settings-container{
    overflow-y: scroll;
    margin-bottom: 10px;
}

.paramaters-settings-item{
    margin-right: 20px;
    display: flex;
    min-width: 300px;
    font-size: 1em;
    margin-top: 5px;
    padding: 8px 25px;
    flex-direction: row;
    justify-content: space-between;
    &:hover{
        background-color: rgb(247, 247, 247);
        .parameters-settings-icon-delete{
            display: block;
        }
    }
    .parameters-settings-icon-delete{
        &:hover{
            color:red
        }
        cursor: pointer;
        display: none;
        margin-top: auto;
        margin-bottom: auto;
        font-size: 1.3em;
    }
}

.parameters-settings-add-container{
    justify-content: flex-start;
    margin: 20px 0;
    margin-bottom: 40px;
    .parameters-settings-add-button{
        margin-left: 20px;
        max-height: 50px;
        margin-top: auto;
        min-width: 80px;
    }
}

.parameters-relation-delete-button{
    &:hover{
        cursor: pointer;
        color: gray;
    }
}

.parameteres-employee-cost-category-input{
    margin-left: auto;
    margin-right: auto;
    border:none;
    background-color: white;
    width:100px;
    text-align: center;
    border: 1px solid #dedede;
    &:focus{
        outline:none;
        background-color: rgb(245, 245, 245);
    }
    &::-webkit-outer-spin-button{
        -webkit-appearance: none;
        margin: 0;
    }
    &::-webkit-inner-spin-button{
        -webkit-appearance: none;
        margin: 0;
    }
    &[type=number]{
        -moz-appearance: textfield;
    }
    &:hover{
        background-color: rgb(245, 245, 245);
        cursor: pointer;
    }
}

