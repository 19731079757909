.progress-bar-wbs {
    width: 350px;
    margin-left: 100px;
}

.progress-bar-wbs-content {
    margin-top: 5px;
    width: 100%;
    background-color: #e0e0e0;
    padding: 3px;
    border-radius: 5px;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, .2);
}

.progress-bar-wbs-fill {
    display: block;
    height: 22px;
    background-color: #E715C5;
    border-radius: 4px;
    transition: width 500ms ease-in-out;
}