#plus{
    box-shadow: 5px 5px 12px -6px #000000;
}

.vertical-line{
    border-left: 2px solid #000;
    display: inline-block;
    height: 10px;
    margin-left: 5px;
}

.task{
    max-height: 100px;
}
.content{
    float: left;
}
.code{
    margin-top: 50px;
    margin-left: 10px;
    margin-right: 10px;
    color: #E5343A;
}