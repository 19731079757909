.error-fallback {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 0 50px;
    h1 {
        line-height: 1;
    }
    h2 {
        font-weight: 500;
        padding-top: 10px;
        &:first-of-type {
            padding-top: 30px;
        }
    }
    & > div {
        width: 800px;
    }
}

.error-fallback-buttons {
    display: flex;
    padding-top: 30px;
    button:first-child {
        margin-right: 10px;
    }
}