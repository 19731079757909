.loading-fallback {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    padding: 0 50px;
}

.loading-fallback-text {
    margin-bottom: 15px !important;
}