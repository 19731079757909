.invoice-forecast-list-title{
    font-size: 25px;
    padding-left: 20px;
}
.invoice-forecast-list-row-value{
    padding:10px 30px;
    font-size: 15px;
}

.invoice-forecast-list-arrow-up{
    font-size: 20px;
    &:hover{
        color:black !important;
        cursor:pointer;
    }
}

.invoice-forecast-list-arrow-up-disabled{
    font-size: 20px;
}


.invoice-forecast-list-table-first-remove{
    tr{
        td:first-child{
            border:none
        }
    }
}

.invoice-forecast-list-table{
    tr:last-child{
        td{
            border: none;
        }
    }
}

.invoice-forecast-list-edit-icon{
    font-size: 18px;
    &:hover{
        color:black !important;
        cursor:pointer;
    }
}

.currencyinput {
    border: 1px inset #ccc;
}

.invoices-list-remove-button{
    font-size: 25px;
    &:hover{
        color:black !important;
        cursor:pointer;
    }
}

.invoices-list-input-comment{
    border: none;
    margin-left: 30px;
    &:focus{
        outline:none;
    }
    &::-webkit-outer-spin-button{
        -webkit-appearance: none;
        margin: 0;
    }
    &::-webkit-inner-spin-button{
        -webkit-appearance: none;
        margin: 0;
    }
    width:60%
}

.invoices-explorer-main-card{
    overflow-y: scroll;
}